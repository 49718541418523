
import { defineComponent, computed } from "vue";
import { i18n } from "@/i18n";

export default defineComponent({
  name: "Publications",
  setup() {
    const { t } = i18n.global;

    const list = computed(() => [
      {
        title: t("links.items.a.title"),
        href: t("links.items.a.href")
      },
      {
        title: t("links.items.b.title"),
        href: t("links.items.b.href")
      },
      // {
      //   title: t("links.items.c.title"),
      //   href: t("links.items.c.href")
      // },
    ]);

    return {
      t,
      list,
    };
  }
});
